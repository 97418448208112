import { ButtonFilter, InputSearch } from "@bhawanadevteam/react-core"
import { Formik } from "formik"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { RegistrasiKaryawanApi } from "../../../../api"
import {
  Alert,
  CreateButton,
  CRUDLayout,
  DataStatus,
  DeleteButton,
  DeleteModal,
  Pagination,
  ReadButton,
  Switch,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  UpdateButton
} from "../../../../components"
import { TableNumber } from "../../../../utilities"
import { ModalFilter } from "./Comps"
import { ButtonGroup } from "react-bootstrap"

const RegistrasiKaryawan = ({ setNavbarTitle }) => {
  // Title
  const title = "Registrasi Pekerja"

  // indikator pemanggilan data sedang dimuat di server
  const [isLoading, setIsLoading] = useState(true)

  // indikator apakah sedang melakukan searching
  const [isSearching, setIsSearching] = useState(false)

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState("")

  // data jenis anggaran
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [dataLength, setDataLength] = useState(10)
  const [dataCount, setDataCount] = useState(0)
  const [deleteData, setDeleteData] = useState({})
  const [isDeleteData, setIsDeleteData] = useState(false)
  const [filter, setFilter] = useState({
    id_jabatan: undefined,
    id_unit_organisasi: undefined,
    active: false,
  })

  const [modalFilter, setModalFilter] = useState(false)

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false)
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    searchKey: "",
  })

  const history = useHistory()

  // request data dari server
  const getData = () => {
    setIsLoading(true)
    setIsSearching(false)

    // request data ke server
    RegistrasiKaryawanApi.getPage({
      per_page: dataLength,
      page: page,
      q: searchKey,
      ...filter,
    })
      .then((res) => {
        setData(res.data.data)
        setTotalPage(res.data.total_page)
        setDataCount(res.data.data_count)
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Gagal untuk memuat data",
        })
        setShowAlert(true)
      })
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          })
          setShowAlert(true)
        } else {
          setShowAlert(false)
        }
        setIsLoading(false)
      })
  }

  // menangani hide/show data
  const changeDataStatus = (status, id) => {
    setIsLoading(true)
    setShowAlert(false)

    const value = {
      id_karyawan: id,
      is_hidden: !status ? "true" : "false",
    }

    const onLoadedSuccess = () => {
      setIsSearching(false)
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      })
      setShowAlert(true)
    }

    const onLoadedFailed = () => {
      setIsSearching(false)
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      })
      setShowAlert(true)
    }

    RegistrasiKaryawanApi.hide(value)
      .then(() => onLoadedSuccess())
      .catch(() => onLoadedFailed())
      .finally(() => getData())
  }

  useEffect(() => {
    // set judul di Navbar
    setNavbarTitle(title)

    // jalankan function request data ke server
    getData()

    // menangani pembersihan saat fetching data
    return () => {
      setIsLoading(false)
    }
  }, [
    setNavbarTitle,
    page,
    dataLength,
    searchKey,
    filter.id_jabatan,
    filter.id_unit_organisasi,
  ])

  const formInitialValuesFilter = {
    id_jabatan: filter?.id_jabatan,
    id_unit_organisasi: filter?.id_unit_organisasi,
  }

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter)

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1,
    })
    onFilterButtonClickkHandler()
  }

  // modal hapus
  const HapusModal = () => {
    // set id dari data yang ingin dihapus
    const idData = deleteData.id_karyawan
    const values = { id_karyawan: idData }

    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false)

    // menangani pembersihan saat fetching data
    useEffect(() => {
      return () => {
        setBtnLoading(false)
      }
    }, [])

    // request hapus data ke server
    const deleteDataHandler = () => {
      // set delete button loading
      setBtnLoading(true)

      RegistrasiKaryawanApi.delete(values)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          })
        })
        .catch((err) => {
          console.log(err.response)
          // konfigurasi alert
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err.response.data.message})`,
          })
        })
        .finally(() => {
          // menutup modal
          setIsDeleteData(false)
          // menampilkan alert
          setShowAlert(true)
          // request data baru ke server
          getData()
        })
    }

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <span>No. Pekerja : {deleteData.no_karyawan}</span>
        <br></br>
        <span>Nama Pekerja : {deleteData.nama_karyawan}</span>
      </DeleteModal>
    )
  }

  // Tabel
  const Table = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>No. Pekerja</ThFixed>
            <Th>Username</Th>
            <Th>Nama Pekerja</Th>
            <Th>Jabatan</Th>
            <Th>Unit Produksi</Th>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
              <TdFixed>
                <div className="d-flex justify-content-center">
                  <ButtonGroup>
                    <ReadButton
                      noMargin
                      onClick={() =>
                        history.push(
                          "/human-resource/master/karyawan/" + val.id_karyawan
                        )
                      }
                    />
                    <UpdateButton
                      noMargin
                      onClick={() =>
                        history.push(
                          "/human-resource/master/karyawan/ubah/" +
                          val.id_karyawan
                        )
                      }
                    />
                    <DeleteButton
                      noMargin
                      onClick={() => {
                        setDeleteData(val)
                        setIsDeleteData(true)
                      }}
                    />
                  </ButtonGroup>
                  <Switch
                    id={toString(index + 1)}
                    checked={!val.is_hidden}
                    onChange={() =>
                      changeDataStatus(val.is_hidden, val.id_karyawan)
                    }
                  />
                </div>
              </TdFixed>
              <TdFixed>{val.no_karyawan}</TdFixed>
              <Td>{val.username}</Td>
              <Td>{val.nama_karyawan}</Td>
              <Td>{val.nama_jabatan}</Td>
              <Td>{val.nama_unit_produksi}</Td>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={dataCount < dataLength ? dataCount : page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setDataLength(e.target.value)
            setPage(1)
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      )}
    </>
  )

  return (
    <CRUDLayout>
      {/* head */}
      <CRUDLayout.Head>
        {/* search section */}
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value)
                  setPage(1)
                }, 1000)
              }}
              className="mr-2"
            />
            <ButtonFilter
              size="sm"
              className="text-nowrap"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* button section */}
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push("/human-resource/master/karyawan/tambah")
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      {isSearching ? (
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={`Hasil dari pencarian: ${alertConfig.searchKey}`}
          onClose={() => {
            setShowAlert(false)
            getData()
            setIsSearching(false)
          }}
        />
      ) : (
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />
      )}

      {/* Table */}
      {
        // cek apakah data sedang dimuat (loading)
        isLoading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
          data.length > 0 ? (
            <Table />
          ) : (
            <DataStatus text="Tidak ada data" />
          )
      }
      <HapusModal />
      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}
        >
          <ModalFilter
            data={filter}
            setData={setFilter}
            toggle={onFilterButtonClickkHandler}
          />
        </Formik>
      )}
    </CRUDLayout>
  )
}

export default RegistrasiKaryawan
