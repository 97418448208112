import { Formik } from "formik"
import { useEffect, useState } from "react"
import { ButtonGroup, Modal } from "react-bootstrap"
import * as Yup from "yup"
import { LokasiKerjaApi } from "../../../../api"
import {
  ActionButton,
  Alert,
  CreateButton,
  CreateModal,
  CRUDLayout,
  DataStatus,
  DeleteButton,
  DeleteModal,
  Input,
  InputSearch,
  Pagination,
  Switch,
  TBody,
  Td,
  TdFixed,
  TextArea,
  Th,
  THead,
  ThFixed,
  Tr,
  UpdateButton,
  UpdateModal
} from "../../../../components"
import { TableNumber } from "../../../../utilities"

const LokasiKerja = ({ setNavbarTitle }) => {
  // Title
  const title = "Lokasi Kerja"

  // indikator pemanggilan data sedang dimuat di server
  const [isLoading, setIsLoading] = useState(true)

  // indikator apakah sedang melakukan searching
  const [isSearching, setIsSearching] = useState(false)

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState("")

  // menangani modal form tambah
  const [isCreateForm, setIsCreateForm] = useState(false)
  // menangani modal form ubah
  const [isUpdateform, setIsUpdateform] = useState(false)
  // menangani modal hapus data
  const [isDeleteData, setIsDeleteData] = useState(false)

  // data jenis anggaran
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [dataLength, setDataLength] = useState(10)
  const [dataCount, setDataCount] = useState(0)

  // menampung data yang akan diubah
  const [updateData, setUpdateData] = useState([])
  // menampung data yang akan dihapus
  const [deleteData, setDeleteData] = useState([])

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false)
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    searchKey: "",
  })

  // request data dari server
  const getData = () => {
    setIsLoading(true)
    setIsSearching(false)

    // request data ke server
    LokasiKerjaApi.getPage(page, dataLength, searchKey)
      .then((res) => {
        setData(res.data.data)
        setTotalPage(res.data.total_page)
        setDataCount(res.data.data_count)
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Gagal untuk memuat data",
        })
        setShowAlert(true)
      })
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          })
          setShowAlert(true)
        } else {
          setShowAlert(false)
        }
        setIsLoading(false)
      })
  }

  // request search data dari server
  const searchData = (e) => {
    // mencegah terjadinya page reload
    e.preventDefault()

    // set loading
    setIsLoading(true)

    // nilai dari form search
    const key = searchKey

    LokasiKerjaApi.search(key)
      .then((res) => {
        setData(res.data.data)
        setTotalPage(res.data.total_page)
        setAlertConfig({
          variant: "primary",
          searchKey: key,
        })
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Gagal untuk memuat data",
        })
      })
      .finally(() => {
        setPage(1)
        setIsSearching(true)
        setIsLoading(false)
        setShowAlert(true)
      })
  }

  // menangani hide/show data
  const changeDataStatus = (status, id) => {
    setIsLoading(true)
    setShowAlert(false)

    const value = {
      id_lokasi_kerja: id,
    }

    const onLoadedSuccess = () => {
      setIsSearching(false)
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      })
      setShowAlert(true)
    }

    const onLoadedFailed = () => {
      setIsSearching(false)
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      })
      setShowAlert(true)
    }

    status === true
      ? LokasiKerjaApi.show(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData())
      : LokasiKerjaApi.hide(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData())
  }

  useEffect(() => {
    // set judul di Navbar
    setNavbarTitle(title)

    // jalankan function request data ke server
    getData()

    // menangani pembersihan saat fetching data
    return () => {
      setIsLoading(false)
    }
  }, [setNavbarTitle, page, dataLength, searchKey])

  // modal tambah
  const TambahModal = () => {
    // nilai awal form
    const formInitialValues = {
      nama_lokasi_kerja: "",
      keterangan: "",
    }

    // skema validasi form
    const formValidationSchema = Yup.object().shape({
      nama_lokasi_kerja: Yup.string().required("Masukan nama lokasi kerja"),
    })

    // request tambah data ke server
    const formSubmitHandler = (values) => {
      LokasiKerjaApi.create(values)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: "primary",
            text: "Tambah data berhasil!",
          })
        })
        .catch(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal!`,
          })
        })
        .finally(() => {
          // menutup modal
          setIsCreateForm(false)
          // menampilkan alert
          setShowAlert(true)
          // request data baru ke server
          getData()
        })
    }

    return (
      <CreateModal
        show={isCreateForm}
        onHide={() => setIsCreateForm(false)}
        title={title}
      >
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Input
                  label="Nama Lokasi Kerja"
                  placeholder="Masukan nama lokasi kerja"
                  name="nama_lokasi_kerja"
                  value={values.nama_lokasi_kerja}
                  error={
                    errors.nama_lokasi_kerja &&
                    touched.nama_lokasi_kerja &&
                    true
                  }
                  errorText={errors.nama_lokasi_kerja}
                  onChange={handleChange}
                />
                <TextArea
                  label="Keterangan"
                  placeholder="Masukan keterangan"
                  name="keterangan"
                  value={values.keterangan}
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                  onChange={handleChange}
                />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Tambah Data"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </CreateModal>
    )
  }

  // modal ubah
  const UbahModal = () => {
    // nilai awal form
    const formInitialValues = {
      nama_lokasi_kerja: updateData.nama_lokasi_kerja,
      keterangan: updateData.keterangan,
    }

    // skema validasi form
    const formValidationSchema = Yup.object().shape({
      nama_lokasi_kerja: Yup.string().required("Masukan nama lokasi kerja"),
    })

    // request ubah data ke server
    const formSubmitHandler = (values) => {
      const finalValues = {
        id_lokasi_kerja: updateData.id_lokasi_kerja,
        ...values,
      }

      LokasiKerjaApi.update(finalValues)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          })
        })
        .catch(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: "danger",
            text: `Ubah data gagal!`,
          })
        })
        .finally(() => {
          // menutup modal
          setIsUpdateform(false)
          // menampilkan alert
          setShowAlert(true)
          // request data baru ke server
          getData()
        })
    }

    return (
      <UpdateModal
        show={isUpdateform}
        onHide={() => setIsUpdateform(false)}
        title={title}
      >
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Input
                  label="Nama Lokasi Kerja"
                  placeholder="Masukan nama lokasi kerja"
                  name="nama_lokasi_kerja"
                  value={values.nama_lokasi_kerja}
                  error={
                    errors.nama_lokasi_kerja &&
                    touched.nama_lokasi_kerja &&
                    true
                  }
                  errorText={errors.nama_lokasi_kerja}
                  onChange={handleChange}
                />
                <TextArea
                  label="Keterangan"
                  placeholder="Masukan keterangan"
                  name="keterangan"
                  value={values.keterangan}
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                  onChange={handleChange}
                />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="success"
                  text="Ubah Data"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    )
  }

  // modal hapus
  const HapusModal = () => {
    // set id dari data yang ingin dihapus
    const idData = deleteData.id_lokasi_kerja
    const values = { id_lokasi_kerja: idData }

    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false)

    // menangani pembersihan saat fetching data
    useEffect(() => {
      return () => {
        setBtnLoading(false)
      }
    }, [])

    // request hapus data ke server
    const deleteDataHandler = () => {
      // set delete button loading
      setBtnLoading(true)

      LokasiKerjaApi.delete(values)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          })
        })
        .catch(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal!`,
          })
        })
        .finally(() => {
          // menutup modal
          setIsDeleteData(false)
          // menampilkan alert
          setShowAlert(true)
          // request data baru ke server
          getData()
        })
    }

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <span>Nama Lokasi Kerja : {deleteData.nama_lokasi_kerja}</span>
      </DeleteModal>
    )
  }

  // Tabel
  const Table = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <Th>Nama Lokasi Kerja</Th>
            <Th>Keterangan</Th>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
              <TdFixed>
                <div className="d-flex justify-content-center">
                  <ButtonGroup>
                    <UpdateButton
                      noMargin
                      onClick={() => {
                        setUpdateData(val)
                        setIsUpdateform(true)
                      }}
                    />
                    <DeleteButton
                      noMargin
                      onClick={() => {
                        setDeleteData(val)
                        setIsDeleteData(true)
                      }}
                    />
                  </ButtonGroup>
                  <Switch
                    id={toString(index + 1)}
                    checked={!val.is_hidden}
                    onChange={() =>
                      changeDataStatus(val.is_hidden, val.id_lokasi_kerja)
                    }
                  />
                </div>
              </TdFixed>
              <Td>{val.nama_lokasi_kerja}</Td>
              <Td>{val.keterangan}</Td>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={dataCount < dataLength ? dataCount : page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setDataLength(e.target.value)
            setPage(1)
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      )}
    </>
  )

  return (
    <CRUDLayout>
      {/* head */}
      <CRUDLayout.Head>
        {/* search section */}
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value)
                  setPage(1)
                }, 1000)
              }}
              onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* button section */}
        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => setIsCreateForm(true)} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* aLert */}
      {isSearching ? (
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={`Hasil dari pencarian: ${alertConfig.searchKey}`}
          onClose={() => {
            setShowAlert(false)
            getData()
            setIsSearching(false)
          }}
        />
      ) : (
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />
      )}

      {/* table section */}
      {
        // cek apakah data sedang dimuat (loading)
        isLoading === true ? (
          // loading
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
          data.length > 0 ? (
            // Ada data
            <Table />
          ) : (
            // Tidak ada data
            <DataStatus text="Tidak ada data" />
          )
      }

      {/* Modal */}
      <TambahModal />
      <UbahModal />
      <HapusModal />
    </CRUDLayout>
  )
}

export default LokasiKerja
