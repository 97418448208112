import React from 'react'
import {
  Button
} from 'react-bootstrap'
import {
  IoAddOutline
} from 'react-icons/io5'
import { GuestMiddleware } from 'utilities/GuestMiddleware'

const CreateButton = ({ onClick }) => {
  return (
    <GuestMiddleware>
      <Button
        variant="primary"
        onClick={onClick}
        className="mx-1 mb-1"
      >
        <IoAddOutline size={18} />
        <span className="ml-2 text-uppercase">
          Tambah
        </span>
      </Button>
    </GuestMiddleware>
  )
}

export default CreateButton
