// React
import { useEffect, useState } from "react"

// Component
import { ButtonGroup, Col, Modal, Row } from "react-bootstrap"
import {
  ActionButton,
  Alert,
  CreateButton,
  CRUDLayout,
  DataStatus,
  DeleteButton,
  DeleteModal,
  InputSearch,
  Pagination,
  ReadButton,
  SelectSearch,
  Switch,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  UpdateButton,
} from "../../../../components"
import { TableNumber } from "../../../../utilities"

// API
import Axios from "axios"
import { HakAksesDivisiApi } from "../../../../api"

// Icon
import { IoArrowUpOutline } from "react-icons/io5"

const HakAksesDivisi = ({ setNavbarTitle }) => {
  // Title
  const title = "Hak Akses Modul"

  // indikator pemanggilan data sedang dimuat di server
  const [isLoading, setIsLoading] = useState(true)

  // indikator apakah sedang melakukan searching
  const [isSearching, setIsSearching] = useState(false)

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState("")
  const [hakAkses, setHakAkses] = useState("")

  // data jenis anggaran
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [dataLength, setDataLength] = useState(10)
  const [dataCount, setDataCount] = useState(0)
  const [dataKaryawan, setDataKaryawan] = useState([])
  const [dataDivisi, setDataDivisi] = useState([])
  const [dataDivisiFilter, setDataDivisiFilter] = useState([])
  const [modal, setModal] = useState({
    show: false,
    tipe: "",
    data: {},
  })

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false)
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    searchKey: "",
  })

  // request data dari server
  const getData = () => {
    setIsLoading(true)
    setIsSearching(false)

    const value = {
      page,
      per_page: dataLength,
      q: searchKey,
      tipe_hak_akses: "divisi",
    }
    if (hakAkses !== "") {
      value.kode_hak_akses = hakAkses
    }

    // request data ke server
    HakAksesDivisiApi.hak_akses_karyawan(value)
      .then((res) => {
        setData(res.data.data)
        setTotalPage(res.data.total_page)
        setDataCount(res.data.data_count)
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Gagal untuk memuat data",
        })
        setShowAlert(true)
      })
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          })
          setShowAlert(true)
        } else {
          setShowAlert(false)
        }
        setIsLoading(false)
      })
  }

  useEffect(() => {
    // set judul di Navbar
    setNavbarTitle(title)

    setIsLoading(true)

    Axios.all([
      HakAksesDivisiApi.hak_akses_karyawan({
        page,
        per_page: dataLength,
        q: searchKey,
        tipe_hak_akses: "divisi",
      }),
      HakAksesDivisiApi.getDropdown({ is_hidden: false }),
      HakAksesDivisiApi.getDivisi(),
    ])
      .then(
        Axios.spread((res, karyawan, Modul) => {
          setData(res.data.data)
          setTotalPage(res.data.total_page)
          setDataCount(res.data.data_count)
          setDataKaryawan(karyawan.data.data)
          setDataDivisi(Modul.data.data)
          setDataDivisiFilter([
            {
              kode_hak_akses: "",
              nama_hak_akses: "Semua Modul",
            },
            ...Modul.data.data,
          ])
        })
      )
      .finally(() => setIsLoading(false))
  }, [setNavbarTitle])

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [page, dataLength, searchKey, hakAkses])

  // modal tambah
  const DataModal = () => {
    const [dataTable, setDataTable] = useState("")
    const [idDivisi, setIdDivisi] = useState("")
    const [dataId, setDataId] = useState([])
    const [loadingAtribut, setLoadingAtribut] = useState({
      karyawan: true,
      Modul: true,
    })

    const hideModal = () => {
      setModal({
        show: false,
        tipe: "",
        data: {},
      })
      setDataTable("")
      setIdDivisi("")
    }

    useEffect(() => {
      setLoadingAtribut({
        karyawan: true,
        Modul: true,
      })

      if (modal?.tipe === "ubah" || modal?.tipe === "detail") {
        const myPromise = new Promise((resolve) => {
          setDataId({
            id_karyawan: modal?.data.id_karyawan,
          })
          setDataTable(modal?.data.id_karyawan)
          setIdDivisi(modal?.data.kode_hak_akses_parent)
          resolve()
        })

        myPromise.then(() =>
          setLoadingAtribut({
            karyawan: false,
            Modul: false,
          })
        )
      } else {
        setLoadingAtribut({
          karyawan: false,
          Modul: false,
        })
      }
    }, [])

    const DivisiTable = () => {
      const [data, setData] = useState([])
      const [dataLoading, setDataLoading] = useState(false)

      const getDataKaryawan = () => {
        setDataLoading(true)

        HakAksesDivisiApi.get(dataTable, idDivisi)
          .then((res) => {
            setData(res.data.data)
          })
          .finally(() => setDataLoading(false))
      }

      const changeStatus = (index) => {
        let dataAwal = data.filter((res) => res.tipe_hak_akses === "menu")
        dataAwal[index] = {
          ...dataAwal[index],
          status: !dataAwal[index].status,
        }
        setData(dataAwal)
      }

      useEffect(() => {
        dataTable !== "" && idDivisi !== "" && getDataKaryawan()
      }, [dataTable, idDivisi])

      const formSubmitedHandler = () => {
        const dataKode = data
          .filter((res) => res.status === true)
          .map((val) => val.kode_hak_akses)
        console.log(idDivisi)

        dataKode.unshift(idDivisi)

        let postData = {
          id_karyawan: dataId.id_karyawan,
          kode_hak_akses: dataKode,
          kode_hak_akses_parent: idDivisi,
          tipe_hak_akses: "menu, divisi",
        }

        HakAksesDivisiApi.create(postData)
          .then(() => {
            setAlertConfig({
              variant: "primary",
              text: `Tambah data berhasil!`,
            })
          })
          .catch((err) => {
            setAlertConfig({
              variant: "danger",
              text: `Tambah data gagal! (${err})`,
            })
          })
          .finally(() => {
            setShowAlert(true)
            hideModal()
            getData()
          })
      }
      return (
        <>
          {dataLoading ? (
            <DataStatus loading={true} text="Memuat..." />
          ) : dataTable && idDivisi ? (
            <>
              <Table>
                <THead>
                  <Tr>
                    <ThFixed>No.</ThFixed>
                    <Th>Nama Menu</Th>
                    <Th>Nama Tipe</Th>
                    {modal.tipe !== "detail" && <ThFixed>Aksi</ThFixed>}
                  </Tr>
                </THead>
                <TBody>
                  {modal.tipe !== "detail"
                    ? data.map((val, index) => (
                      <Tr key={index}>
                        <TdFixed>{index + 1}</TdFixed>
                        <Td>{val.nama_hak_akses}</Td>
                        <Td>{val.nama_tipe_menu}</Td>
                        <TdFixed>
                          <Switch
                            checked={val.status ? true : false}
                            onChange={() => changeStatus(index)}
                          />
                        </TdFixed>
                      </Tr>
                    ))
                    : data
                      .filter((val) => val.status)
                      .map((val, index) => (
                        <Tr key={index}>
                          <TdFixed>{index + 1}</TdFixed>
                          <Td>{val.nama_hak_akses}</Td>
                          <Td>{val.nama_tipe_menu}</Td>
                        </Tr>
                      ))}
                </TBody>
              </Table>
              {modal.tipe !== "detail" && (
                <div className="d-flex justify-content-end">
                  <ActionButton
                    type="button"
                    onClick={formSubmitedHandler}
                    text="Simpan Hak Akses Modul"
                  />
                </div>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-center mt-3">
              <IoArrowUpOutline size="18" />
              <h5 className="ml-2">Pilih data terlebih dahulu</h5>
            </div>
          )}
        </>
      )
    }

    return (
      <Modal
        show={modal?.show && modal?.tipe !== "hapus"}
        onHide={hideModal}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">
            <h6 className="mb-0 text-capitalize">
              {modal?.tipe ?? ""} Data {title ?? ""}
            </h6>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col sm={6} lg={8}>
              {modal?.tipe !== "detail" ? (
                <SelectSearch
                  label="Karyawan"
                  name="id_karyawan"
                  placeholder="Pilih karyawan"
                  onChange={(val) => {
                    setDataTable(val.value)
                    setDataId({
                      ...dataId,
                      id_karyawan: val.value,
                      nama_karyawan: val.label,
                    })
                  }}
                  option={dataKaryawan.map((val) => {
                    return {
                      value: val.id_karyawan,
                      label: `${val.no_karyawan} - ${val.nama_karyawan} - ${val.nama_jabatan} - ${val.nama_unit_produksi}`,
                    }
                  })}
                  defaultValue={
                    Boolean(modal?.tipe === "ubah" || modal?.tipe === "detail")
                      ? dataKaryawan.map((val) => {
                        if (val.id_karyawan === dataTable) {
                          return {
                            label: `${val.no_karyawan} - ${val.nama_karyawan} - ${val.nama_jabatan} - ${val.nama_unit_produksi}`,
                            value: val.id_karyawan,
                          }
                        }
                      })
                      : ""
                  }
                  isDisabled={
                    Boolean(modal?.tipe === "ubah" || modal?.tipe === "detail")
                      ? true
                      : false
                  }
                  loading={loadingAtribut.karyawan}
                />
              ) : (
                <>
                  <div>
                    <small>Karyawan Pengakses</small>
                  </div>
                  <div>
                    <b>
                      {!loadingAtribut.karyawan &&
                        dataKaryawan.find(
                          (val) => val.id_karyawan === dataTable
                        ).nama_karyawan}
                    </b>
                  </div>
                </>
              )}
            </Col>
            <Col sm={6} lg={4}>
              {modal?.tipe !== "detail" ? (
                <SelectSearch
                  label="Modul"
                  name="kode_hak_akses"
                  placeholder="Pilih Modul"
                  onChange={(val) => {
                    setIdDivisi(val.value)
                  }}
                  option={dataDivisi.map((val) => {
                    return {
                      value: val.kode_hak_akses,
                      label: val.nama_hak_akses,
                    }
                  })}
                  defaultValue={
                    Boolean(modal?.tipe === "ubah" || modal?.tipe === "detail")
                      ? dataDivisi.map((val) => {
                        if (val.kode_hak_akses === idDivisi) {
                          return {
                            value: val.kode_hak_akses,
                            label: val.nama_hak_akses,
                          }
                        }
                      })
                      : ""
                  }
                  isDisabled={
                    Boolean(modal?.tipe === "ubah" || modal?.tipe === "detail")
                      ? true
                      : false
                  }
                  loading={loadingAtribut.Modul}
                />
              ) : (
                <>
                  <div>
                    <small>Modul</small>
                  </div>
                  <div>
                    <b>
                      {!loadingAtribut.Modul &&
                        dataDivisi.find(
                          (val) => val.kode_hak_akses === idDivisi
                        ).nama_hak_akses}
                    </b>
                  </div>
                </>
              )}
            </Col>
          </Row>
          <DivisiTable />
        </Modal.Body>
      </Modal>
    )
  }

  // modal hapus
  const HapusModal = () => {
    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false)

    // request hapus data ke server
    const deleteDataHandler = () => {
      // set delete button loading
      setBtnLoading(true)

      HakAksesDivisiApi.delete({
        id_karyawan: modal?.data?.id_karyawan,
        kode_hak_akses: modal?.data?.kode_hak_akses,
      })
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          })
        })
        .catch((err) => {
          // konfigurasi alert
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err.response.data.message})`,
          })
        })
        .finally(() => {
          setShowAlert(true)
          setModal({
            show: false,
            tipe: "",
            data: {},
          })
          getData()
        })
    }

    return (
      <DeleteModal
        show={modal?.show && modal?.tipe === "hapus"}
        onHide={() =>
          setModal({
            show: false,
            tipe: "",
            data: {},
          })
        }
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Nama Karyawan : {modal?.data?.nama_karyawan}</div>
        <div>Modul : {modal?.data?.nama_hak_akses}</div>
      </DeleteModal>
    )
  }

  // Tabel
  const DataTable = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <Th>Modul</Th>
            <Th>Karyawan Pengakses</Th>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
              <TdFixed>
                <div className="d-flex justify-content-center">
                  <ButtonGroup>
                    <ReadButton
                      noMargin
                      onClick={() =>
                        setModal({
                          show: true,
                          tipe: "detail",
                          data: {
                            id_karyawan: val.id_karyawan,
                            kode_hak_akses_parent: val.kode_hak_akses,
                          },
                        })
                      }
                    />
                    <UpdateButton
                      noMargin
                      onClick={() =>
                        setModal({
                          show: true,
                          tipe: "ubah",
                          data: {
                            id_karyawan: val.id_karyawan,
                            kode_hak_akses_parent: val.kode_hak_akses,
                          },
                        })
                      }
                    />
                    <DeleteButton
                      noMargin
                      onClick={() =>
                        setModal({
                          show: true,
                          tipe: "hapus",
                          data: {
                            id_karyawan: val.id_karyawan,
                            nama_karyawan: val.nama_karyawan,
                            kode_hak_akses: val.kode_hak_akses,
                            nama_hak_akses: val.nama_hak_akses,
                          },
                        })
                      }
                    />
                  </ButtonGroup>
                </div>
              </TdFixed>
              <Td>{val.nama_hak_akses}</Td>
              <Td>
                {`${val.no_karyawan} - ${val.nama_karyawan} (${val.username}) - ${val.nama_jabatan} - ${val.nama_unit_produksi}`}
              </Td>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={dataCount < dataLength ? dataCount : page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setDataLength(e.target.value)
            setPage(1)
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      )}
    </>
  )

  return (
    <CRUDLayout>
      {/* head */}
      <CRUDLayout.Head>
        {/* search section */}
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value)
                  setPage(1)
                }, 1000)
              }}
              onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* button section */}
        <CRUDLayout.HeadButtonSection>
          <div className="d-flex">
            <div style={{ width: 200 }}>
              <SelectSearch
                placeholder="Filter Modul"
                onChange={(val) => setHakAkses(val.value)}
                option={dataDivisiFilter.map((val) => {
                  return {
                    value: val.kode_hak_akses,
                    label: val.nama_hak_akses,
                  }
                })}
                defaultValue={
                  hakAkses
                    ? dataDivisiFilter.map((val) => {
                      if (val.kode_hak_akses === hakAkses) {
                        return {
                          value: val.kode_hak_akses,
                          label: val.nama_hak_akses,
                        }
                      }
                    })
                    : ""
                }
              />
            </div>

            <CreateButton
              onClick={() =>
                setModal({
                  show: true,
                  tipe: "tambah",
                })
              }
            />
          </div>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      {isSearching ? (
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={`Hasil dari pencarian: ${alertConfig.searchKey}`}
          onClose={() => {
            setShowAlert(false)
            getData()
            setIsSearching(false)
          }}
        />
      ) : (
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />
      )}

      {/* Table */}
      {
        // cek apakah data sedang dimuat (loading)
        isLoading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
          data.length > 0 ? (
            <DataTable />
          ) : (
            <DataStatus text="Tidak ada data" />
          )
      }
      {/* <HapusModal /> */}
      {modal?.show && modal?.tipe !== "hapus" && <DataModal />}
      {modal?.show && modal?.tipe === "hapus" && <HapusModal />}
    </CRUDLayout>
  )
}

export default HakAksesDivisi
